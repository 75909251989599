/* Resume */

#resume {

	.link-to {
		position: relative;
		top: -4.5em;
	}

	.link-container h4 {
		text-decoration:none;
		border-bottom: none;
		display: inline-flex;

		a {
				padding: 0 .5em;
				margin: 0;
		}
	}

	.education {
		margin-bottom: 2em;

		.title {
			text-align: center;
			margin-bottom: 2em;
		}
	}

	.experience, .volunteer, .courses {
		.title {
			text-align: center;
			margin-bottom: 2em;
		}
		.job {
			margin: 1em;
			p{
				margin: 0;
			}
		}
	}

	.jobs-container {
		.points {
			li {
				margin: 0;
				padding: 0;
					font-size: .9em;
			}
		}
	}

	.courses {
		
		.title {
			text-align: center;
		}
		.jobs-container {
			padding-bottom: 1.0em;	
		}
		ul {
			padding: 0em;
		}
	}

	.course-info-container {
		text-align: center;
		margin-top: -1em;
		padding-bottom: 2em;
		font-size: 0.9em;
	}

	.course-info {
		letter-spacing: 0.20em;
		text-transform: uppercase;
		padding-left: 1.0em;
		padding-right: 1.0em;
	}

	.Collapsible {
		font-family: "Raleway", Helvetica, sans-serif;
		font-size: 0.9em;
	}
	.header-icon {
		display: inline-flex;
	}
	.svg-inline--fa {
		font-size: 1.5em;
		margin-left: 1.0em;
	}
	

	.Collapsible__contentInner {
		padding-left: 2.0em;
	}

	.references {
		margin: 2em 0;
		.title {
			text-align: center;
		}
	}
	}

	.daterange {
		margin-bottom: .2em;
	}

	.degree-container {
		h4 {
			font-weight: normal;
		}

		.degree {
			margin-bottom: .1em;
			text-transform: none;
			letter-spacing: 0.16em;
			font-size: .8em;
		}

		.school {
			text-transform: none;
			padding-top: 0.3em;
			margin-bottom: 1em;
		}
	}

	.courses .course-name {
		display: inline;
		font-family: "Raleway", Helvetica, sans-serif;
		font-size: 0.7em;
	font-weight: 400;
	letter-spacing: 0.25em;
	line-height: 2.5;
	margin-top: -2em;
	text-transform: uppercase;
	}

	.Collapsible__trigger {
		@include vendor('appearance', 'none');
		@include vendor('transition', (
			'background-color #{_duration(transition)} ease',
			'color #{_duration(transition)} ease'
		));

		background-color: transparent;
			color: _palette(fg-bold) !important;
			cursor: pointer;

			&.is-open {
				.svg-inline--fa {
					transform: rotateZ(180deg);	
				}
			}

			&:hover {
				box-shadow: inset 0 0 0 1px _palette(accent);
				color: _palette(accent) !important;

				&:active {
					background-color: transparentize(_palette(accent), 0.95);
					
				}
			}

			&:before, &:after {
				color: _palette(fg-light);
				position: relative;
			}

			&:before {
				left: -1em;
				padding: 0 0 0 0.75em;
			}

			&:after {
				left: 1em;
				padding: 0 0.75em 0 0;
				
			}

			&.fit {
				display: block;
				margin: 0 0 (_size(element-margin) * 0.5) 0;
				width: 100%;
			}

			&.big {
				font-size: 0.7em;
				padding: 0 3em;
			}

			&.small {
				font-size: 0.5em;
			}

			&.disabled,
			&:disabled {
				@include vendor('pointer-events', 'none');
				color: _palette(border) !important;

				&:before {
					color: _palette(border) !important;
				}
			}
		}

		.course-dot {
			display: inline;
			// display: none;
		}

		@include breakpoint(small) {
			.course-dot {
				display: none;
			}
			.course-container a{
				display: block;
			}
			.course-info {
				display: inline-block;
				padding-left: 0.5em !important;;
				padding-right: 0.5em !important;;
			}
		}
